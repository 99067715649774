import React from 'react'
import { compose, mapProps } from 'recompose'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from 'reactstrap';
import ReactTable from 'react-table';
import TableData from './TableData';
import moment from 'moment';

const GroupTable = ({ data }) => {
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{`Zikr Groups (${data.length})`}</CardTitle>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={data}
              filterable
              columns={[
                {
                  Header: "ID",
                  accessor: "id"
                },
                {
                  Header: "Name",
                  accessor: "name"
                },
                {
                  Header: "Group",
                  accessor: "groupName"
                },
                {
                  Header: "Date Added",
                  accessor: "dateAdded",
                  Cell: (dateAdded) => (dateAdded && dateAdded.value) ? <span>{moment(dateAdded.value).format('DD/MM/YYYY')}</span> : ''
                },
                {
                  Header: "Date Uploaded",
                  accessor: "dateUploaded",
                  Cell: (dateAdded) => (dateAdded && dateAdded.value) ? <span>{moment(dateAdded.value).format('DD/MM/YYYY')}</span> : ''
                },
                {
                  Header: "Image",
                  accessor: "groupImage",
                  Cell: (src) => (src && src.value) ? <img height='75px' width='75px' src={src.value} /> : ''
                },
                {
                  Header: "Audio",
                  accessor: "audio",
                  Cell: (src) => (src && src.value) ? (
                    <audio controls>
                      <source src={src.value} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  ) : ''
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              /*
                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
              */
              className="-striped -highlight primary-pagination"
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default compose(
  TableData,
  mapProps(({ recordings, deleteRecording, ...rest }) => {
    return {
      data: recordings.map(recording => {
        return {
          ...recording,
          key: recording.id,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  
                }}
                color="warning"
                size="lg"
                className="btn-icon btn-link edit"
              >
                <i className="fa fa-edit" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                onClick={() => {
                  deleteRecording(recording.id)
                }}
                color="danger"
                size="lg"
                className="btn-icon btn-link remove"
              >
                <i className="fa fa-times" />
              </Button>{" "}
            </div>
          )
        };
      }),
      deleteRecording: deleteRecording,
      ...rest,
    };
  })
)(GroupTable);