import React from 'react'
import { compose, withState } from 'recompose'
import {
  Container
} from './style'
import ZikrTable from './ZikrTable';
import ZikrForm from './ZikrForm';

const ZikrView = ({ ...props }) => {
  return (
    <Container>
      <h1>Zikr Recordings</h1>
      <ZikrForm { ...props } />
      <ZikrTable />
    </Container>
  );
}

export default compose(
  withState('error', 'setError', false),
  withState('saving', 'setSaving', false),
  withState('showAlert', 'setShowAlert', false),
)(ZikrView);