import React from 'react'
import { compose, withState } from 'recompose'
import {
  Container
} from './style'
import GroupTable from './GroupTable';
import GroupForm from './GroupForm';

const GroupView = ({ ...props }) => {
  return (
    <Container>
      <h1>Zikr Groups</h1>
      <GroupForm {...props} />
      <GroupTable />
    </Container>
  );
}

export default compose(
  withState('error', 'setError', false),
  withState('saving', 'setSaving', false),
  withState('showAlert', 'setShowAlert', false),
)(GroupView);