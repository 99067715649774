import React from 'react';
import { db } from 'services/firebase';

class WithGroups extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groups: []
    }
    this.setGroups = this.setGroups.bind(this);
  }

  setGroups = (groups) => {
    this.setState({
      groups
    })
  }

  componentDidMount() {
    db.collection('groups')
      .onSnapshot((querySnapshot) => {
          let groups = [];
          querySnapshot.forEach(function(doc) {
            groups.push({
              ...doc.data(),
              id: doc.id
            });
          });
          this.setGroups(groups);
      });
  }
  deleteGroup = (id) => {
    db.collection('groups').doc(id).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
  }
  render() {
    return (
      <>
      {this.props.children(this.state.groups, this.deleteGroup.bind(this))}
      </>
    )
  }
}

const TableData = Component => ({ ...rest }) => {
  return (
  <WithGroups>
    {(groups, deleteGroup) => (
      <Component
        groups={groups}
        deleteGroup={deleteGroup}
        { ...rest }
      />
    )}
  </WithGroups>
  )
};

export default TableData;