import Dashboard from "views/Dashboard.jsx";
import Zikr from "views/zikr/Zikr.jsx";
import Groups from "views/groups/Groups.jsx";
import Login from "views/pages/Login.jsx";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-badge",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/zikr",
    name: "Zikr",
    icon: "nc-icon nc-ruler-pencil",
    component: Zikr,
    layout: "/admin"
  },
  {
    path: "/groups",
    name: "Groups",
    icon: "nc-icon nc-layout-11",
    component: Groups,
    layout: "/admin"
  }
];

export default routes;
