import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const prodConfig = {
  apiKey: "AIzaSyCXDUGNgQlbMNzWHtTOnZbYMCB_FMUZloo",
  authDomain: "zikrmobileapp.firebaseapp.com",
  databaseURL: "https://zikrmobileapp.firebaseio.com",
  projectId: "zikrmobileapp",
  storageBucket: "zikrmobileapp.appspot.com",
  messagingSenderId: "599687262243"
};

const devConfig = {
  apiKey: "AIzaSyCXDUGNgQlbMNzWHtTOnZbYMCB_FMUZloo",
  authDomain: "zikrmobileapp.firebaseapp.com",
  databaseURL: "https://zikrmobileapp.firebaseio.com",
  projectId: "zikrmobileapp",
  storageBucket: "zikrmobileapp.appspot.com",
  messagingSenderId: "599687262243"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();

const db = firebase.firestore();

const storage = firebase.storage();

// Disable deprecated features
db.settings({
  timestampsInSnapshots: true
});

export {
  auth,
  firebase,
  db,
  storage
};