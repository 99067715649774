import React from 'react';
import { compose, withState, mapProps, withHandlers } from 'recompose'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert
} from 'reactstrap';
import RecordingUpload from 'components/CustomUpload/RecordingUpload.jsx';
import { 
  firebase,
  db,
  storage
} from 'services/firebase';
import moment from 'moment';
import Select from 'react-select';
import ReactDatetime from 'react-datetime';
import WithGroups from './WithGroups';

const GroupForm = ({ recordingRef, error, setError, saving, setSaving, showAlert, setShowAlert, dismiss, uploadRecording, setGroup, group, groups }) => {
  return (
    <Row>
      <Col >
        <Alert color="info" isOpen={(!error && saving)}>
          Saving...
        </Alert>
        <Alert color="success" isOpen={(!error && !saving && showAlert)} toggle={dismiss}>
          Save Success!
        </Alert>
        <Alert color="danger" isOpen={(error && !saving && showAlert)} toggle={dismiss}>
          {`Error: ${error}`}
        </Alert>
        <Form method="SUBMIT" onSubmit={async (e) => {
              e.preventDefault(); 
              const data = new FormData(e.target)
              setSaving(true);
              setShowAlert(true);
              db.collection('recordings').add({
                  name: data.get('name'),
                  dateAdded: moment(data.get('dateAdded'), 'DD/MM/YYYY').toJSON(),
                  dateUploaded: moment().toJSON(),
                  groupID: group.id,
                  groupName: group.name,
                  groupImage: group.image,
                  audio: await uploadRecording(recordingRef.current.state.file, data.get('name'), group.id)
              })
              .then(function(docRef) {
                  console.log("Document written with ID: ", docRef.id);
                  setSaving(false);
              })
              .catch(function(error) {
                  console.error("Error adding document: ", error);
                  setSaving(false);
                  setError(error)
              });
            }}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Add Recording</CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                  <Col>
                    <label>Name</label>
                    <FormGroup>
                      <Input placeholder='Enter name' type='text' name='name' id='name' />
                    </FormGroup>
                    <label>Date</label>
                    <FormGroup>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Date Picker Here",
                          name: 'dateAdded',
                          id:'dateAdded'
                        }}
                        timeFormat={false}
                        dateFormat={'DD/MM/YYYY'}
                      />
                  </FormGroup>
                  <label>Group</label>
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={group}
                      onChange={value =>
                        setGroup(value)
                      }
                      options={[
                        {
                          value: "",
                          label: "Select a group",
                          isDisabled: true
                        },
                        ...groups
                      ]}
                      placeholder="Single Select"
                    />
                  </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <RecordingUpload ref={recordingRef} />
                  </Col>
                </Row>
            </CardBody>
            <CardFooter>
              <Button className="btn-round" color="info" type="Submit">
                Submit
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default compose(
  WithGroups,
  withState('recordingRef', 'setRecordingRef', React.createRef()),
  withState('group', 'setGroup', ''),
  mapProps(({ groups, ...rest }) => {
    return {
      groups: groups.map(group => {
        return {
          ...group,
          value: group.id,
          label: group.name
        }
      }),
      ...rest
    }
  }),
  withHandlers({
    dismiss: props => () => {
      props.setShowAlert(false);
      props.setSaving(false);
      props.setError(false);
    },
    uploadRecording: props => async (file, name, groupID) => {
      const uploadTask = storage.ref().child(`recordings/${groupID}/${name}.mp3`).put(file);
      uploadTask.on('state_changed', function(snapshot){
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function(error) {
        // Handle unsuccessful uploads
        console.log('Upload Error: ', error);
      });
      const snapShot = await uploadTask;
      return snapShot.ref.getDownloadURL();
    }
  })
)(GroupForm);