import React from 'react';
import { compose, withState, withHandlers } from 'recompose'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert
} from 'reactstrap';
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { 
  firebase,
  db,
  storage
} from 'services/firebase';
import moment from 'moment';

const GroupForm = ({ imageRef, error, setError, saving, setSaving, showAlert, setShowAlert, dismiss, uploadImage }) => {
  return (
    <Row>
      <Col >
        <Alert color="info" isOpen={(!error && saving)}>
          Saving...
        </Alert>
        <Alert color="success" isOpen={(!error && !saving && showAlert)} toggle={dismiss}>
          Save Success!
        </Alert>
        <Alert color="danger" isOpen={(error && !saving && showAlert)} toggle={dismiss}>
          {`Error: ${error}`}
        </Alert>
        <Form method="SUBMIT" onSubmit={async (e) => {
              e.preventDefault(); 
              const data = new FormData(e.target)
              setSaving(true);
              setShowAlert(true);
              db.collection('groups').add({
                  name: data.get('name'),
                  location: data.get('location'),
                  dateAdded: moment().toJSON(),
                  image: await uploadImage(imageRef.current.state.file, data.get('name'))
              })
              .then(function(docRef) {
                  console.log("Document written with ID: ", docRef.id);
                  setSaving(false);
              })
              .catch(function(error) {
                  console.error("Error adding document: ", error);
                  setSaving(false);
                  setError(error)
              });
            }}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Add Group</CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                  <Col>
                    <label>Name</label>
                    <FormGroup>
                      <Input placeholder='Enter name' type='text' name='name' id='name' />
                    </FormGroup>
                    <label>Location</label>
                    <FormGroup>
                      <Input placeholder="Location" type="text" name='location' id='location' />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <ImageUpload ref={imageRef} />
                  </Col>
                </Row>
            </CardBody>
            <CardFooter>
              <Button className="btn-round" color="info" type="Submit">
                Submit
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default compose(
  withState('imageRef', 'setImageRef', React.createRef()),
  withHandlers({
    dismiss: props => () => {
      props.setShowAlert(false);
      props.setSaving(false);
      props.setError(false);
    },
    uploadImage: props => async (file, name) => {
      const uploadTask = storage.ref().child(`images/groups/${name}.jpg`).put(file);
      uploadTask.on('state_changed', function(snapshot){
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function(error) {
        // Handle unsuccessful uploads
        console.log('Upload Error: ', error);
      });
      const snapShot = await uploadTask;
      return snapShot.ref.getDownloadURL();
    }
  })
)(GroupForm);