import React from 'react';
import { db } from 'services/firebase';

class WithRecordings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      recordings: []
    }
  }

  componentDidMount() {
    db.collection('recordings')
      .onSnapshot((querySnapshot) => {
          let recordings = [];
          querySnapshot.forEach(function(doc) {
            recordings.push({
              ...doc.data(),
              id: doc.id
            });
          });
          this.setState({
            recordings
          });
      });
  }
  deleteRecording = (id) => {
    db.collection('recordings').doc(id).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
  }
  render() {
    return (
      <>
      {this.props.children(this.state.recordings, this.deleteRecording.bind(this))}
      </>
    )
  }
}

const RecordingData = Component => ({ ...rest }) => {
  return (
  <WithRecordings>
    {(recordings, deleteRecording) => (
      <Component
        recordings={recordings}
        deleteRecording={deleteRecording}
        { ...rest }
      />
    )}
  </WithRecordings>
  )
};

export default RecordingData;