import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

class RecordingUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      recordingSource: null
    };
    this.handleRecordingChange = this.handleRecordingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleRecordingChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        recordingSource: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      recordingSource: null
    });
    this.refs.fileInput.value = null;
  }
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleRecordingChange} ref="fileInput" />
        <div>
          {(this.state.recordingSource) ? (
            <audio controls>
              <source src={this.state.recordingSource} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <audio controls>
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
        <div>
          {this.state.file === null ? (
            <Button className="btn-round" onClick={() => this.handleClick()}>
              Add Audio
            </Button>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Change
              </Button>
              <Button
                color="danger"
                className="btn-round"
                onClick={() => this.handleRemove()}
              >
                <i className="fa fa-times" />Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default RecordingUpload;
